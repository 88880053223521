<template>
    <div>
        <div class="row my-0">
            <div class="col-12">
                <h1 class="mb-4">{{ SHARED.SupportCenterPage.UserRoles.Title }}</h1>
                <p>{{  SHARED.SupportCenterPage.UserRoles.Paragraph1 }}</p>
                <p>{{  SHARED.SupportCenterPage.UserRoles.Paragraph2 }}</p>
                <p v-html="SHARED.SupportCenterPage.UserRoles.Paragraph3"></p>
            </div>
        </div>
        <recess-divider show-line />
        <div class="row">
            <div class="col-12">
                <h3>{{ SHARED.SupportCenterPage.UserRoles.Block1.Title }}</h3>
                <p>{{ SHARED.SupportCenterPage.UserRoles.Block1.Description }}</p>
            </div>
            <div class="ml-4 d-flex">
                <p>{{ SHARED.SupportCenterPage.UserRoles.Acties }}</p>
                <ul class="u-list-unordered ml-4">
                    <li v-for="(value, key) in SHARED.SupportCenterPage.UserRoles.Block1.List" :key="key">
                        <p>{{ value }}</p>
                    </li>
                </ul>
            </div>
        </div>
        <div class="row">
            <div class="col-12 mt-5">
                <h3>{{ SHARED.SupportCenterPage.UserRoles.Block2.Title }}</h3>
                <p>{{ SHARED.SupportCenterPage.UserRoles.Block2.Description }}</p>
            </div>
            <div class="ml-4 d-flex">
                <p>{{ SHARED.SupportCenterPage.UserRoles.Acties }}</p>
                <ul class="u-list-unordered ml-4">
                    <li v-for="(value, key) in SHARED.SupportCenterPage.UserRoles.Block2.List" :key="key">
                        <p>{{ value }}</p>
                    </li>
                </ul>
            </div>
        </div>
        <div class="row">
            <div class="col-12 mt-5">
                <h3>{{ SHARED.SupportCenterPage.UserRoles.Block3.Title }}</h3>
                <p>{{ SHARED.SupportCenterPage.UserRoles.Block3.Description }}</p>
            </div>
            <div class="ml-4 d-flex">
                <p>{{ SHARED.SupportCenterPage.UserRoles.Acties }}</p>
                <ul class="u-list-unordered ml-4">
                    <li v-for="(value, key) in SHARED.SupportCenterPage.UserRoles.Block3.List" :key="key">
                        <p> {{ value }}</p>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>

<script>
import { SHARED } from "../../constants/EdumsSharedConstants.json";

export default {
    Name: "TermsAndConditions",
    data() {
        return {
            SHARED
        }
    }
}
</script>